import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import io from 'socket.io-client';
import {ApiService} from 'src/app/core/service/api.service';
import {BehaviorSubject, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  public message$: BehaviorSubject<string> = new BehaviorSubject('');
  socket = io(environment.wsUrl, {
    transports: ['websocket'],
  });

  constructor(private apiService: ApiService) {
  }

  public getNewMessage = () => {
    const authToken = JSON.parse(localStorage.getItem('token'));
    this.socket.emit('online', authToken);
    this.socket.on('recive', (message) => {
      this.message$.next(message);
    });
    return this.message$.asObservable();
  };

  // socket: Socket = io(environment.wsUrl);
  // notificationModel: NotificationModel = new NotificationModel();
  // constructor(private apiService: ApiService) {}

  // initSocketConnection() {
  //   const authToken = JSON.parse(localStorage.getItem('token'));
  //   this.socket = io(environment.wsUrl);
  //   this.socket.emit(this.notificationModel.status, authToken);
  //   this.socket.on('recive', (res) => {
  //     console.log(res);
  //   });
  // }
  disconnect() {
    this.socket.disconnect();
  }

  // Notification End Point

  initNotificationData(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/list_all_notifications`, model)
      .pipe(catchError(this.handleError));
  }

  markAllNorificationsIsSeen() {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/see_all_notifications`)
      .pipe(catchError(this.handleError));
  }

  getTasksList(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/list_all_my_tasks`, model)
      .pipe(catchError(this.handleError));
  }

  handleError(error) {
    return throwError(error);
  }
}
