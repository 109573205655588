import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../service/shared.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-files-viewer-btn',
  templateUrl: './files-viewer-btn.component.html',
})
export class FilesViewerBtnComponent implements OnInit {
  @Input() files: any = [];
  @Input() labelName: string;
  @Input() cols = 1;
  docsEditable = false;

  constructor(private sharedService: SharedService) {}

  ngOnInit() {}

  fileType(fileName) {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'pdf';
      case 'jpeg':
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      case 'doc':
      case 'docx':
      case 'wpd':
      case 'tex':
        return 'doc';
      case 'xlsx':
      case 'xls':
        return 'xls';
      case 'zip':
      case 'rar':
        return 'zip';
      default:
        return false;
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onDocumentDownloader(filePath, fileName) {
    const model = {
      file_download: filePath,
    };
    this.sharedService.documentDownloader(model).subscribe((data) => {
      const blob = new Blob([data]);
      // console.log(data.data.name);
      console.log(blob);
      saveAs(blob, `${fileName}`);
    });
  }
}
