import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styles: [],
})
export class NoPermissionComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }
}
